<template lang="pug">
.tabs
	.tabs__list
		.tabs__item(
			v-for="(item, index) in map"
			:class="{'active': index === active}"
			v-on:click="changeActive(index)"
		) {{ item }}
</template>

<script>
export default {
	name: 'BaseTabs',
	props: {
		active: {
			required: true,
			type: [Number, String]
		},
		map: {
			required: true,
			type: [Object, null]
		}
	},
	emits: ['update:active'],
	setup (props, { emit }) {
		const changeActive = index => {
			emit('update:active', index)
		}

		return {
			changeActive
		}
	}
}
</script>

<style scoped lang="scss">
.tabs {
	position: relative;
	font-size: 18px;
	line-height: 30px;

	&__item {
		position: relative;
		display: inline-block;
		cursor: pointer;
		color: $colorSecondary;
		transition: 0.3s ease color;

		&::before {
			content: '';
			position: absolute;
			top: calc(100% - 2px);
			left: 0;
			width: 100%;
			height: 2px;
			border-radius: 10px;
			background-color: transparent;
			transition: 0.3s ease background-color;
		}

		& + & {
			margin-left: 20px;
		}

		&.active {
			color: $colorPrimary;

			&::before {
				background-color: $bgMain;
			}
		}
	}

	@include until-mobile {
		overflow: hidden;

		&__list {
			overflow-x: scroll;
			overflow-y: hidden;
			white-space: nowrap;
			margin: 0 -7.14%;
			padding: 6px 7.14%;
			scroll-padding: 0 7.14%;
			scroll-snap-type: x mandatory;
		}

		&__item {
			scroll-snap-align: start;
			scroll-snap-stop: always;
		}
	}

	@include for-tablet {
		&__item {
			&:hover {
				color: $mainColorHover;
			}
		}
	}
}
</style>
