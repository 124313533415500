<template lang="pug">
.grid
	.title Отчёты
	.subtitle Выберите сотрудника или компанию
	BaseTabs(v-model:active="tab" :map="tabs")
	TheBook(
		:endpoint="BOOKS[tab].endpoint"
		:path="BOOKS[tab].path"
		:left="BOOKS[tab].left"
		:leftSub="BOOKS[tab].leftSub"
		:leftSubTitle="BOOKS[tab].leftSubTitle"
		:plus-hide="true"
		customClick
		@onClick="selectItem"
	)
TheModal(v-model:show="modal"  @update:show="onClose" :title="BOOKS[tab].reportTitle")
	.grid
		BaseGroup(v-if="isFisher")
			BaseCard(
				v-for="(card, id) in fishers"
				:key="id"
				:title="card.workerFIO"
				:subtitle="card.postName"
				:disabled="id !== idFisher && !!idFisher"
				icon="user"
				:button="id !== idFisher ? '○' : '•'"
				:buttonSize="36"
				@onClick="selectFisher(id)"
			)
		BaseInput(
			v-for="(input, key) in inputs"
			:key="key"
			:placeholder="input.placeholder"
			type="date"
			v-model="input.value"
		)
		.flex.flex--jce
			BaseButton(:disabled="isFisher && !idFisher" @click="saveReport") Сформировать
</template>

<script>
import { computed, reactive, toRefs } from 'vue'

import { useApi } from '@/modules/api'
import { useInputsFromTo } from '@/modules/composition/useInputsFromTo'

import BOOKS from '../modules/constants/books'

import ModalJobs from '../components/modals/ModalJobs'

import BaseInput from '../components/ui/BaseInput'
import BaseButton from '../components/ui/BaseButton'
import BaseTabs from '../components/ui/BaseTabs'
import TheBook from '../components/TheBook'
import TheModal from '../components/TheModal'
import BaseCard from '../components/ui/BaseCard'
import BaseGroup from '../components/ui/BaseGroup'
import BaseSelect from '../components/ui/BaseSelect'
import axios from 'axios'
import { useAlert } from '@/modules/composition/useAlert'

export default {
	name: 'TheEvents',
	components: {
		BaseGroup,
		BaseCard,
		TheModal,
		TheBook,
		BaseTabs,
		ModalJobs,
		BaseButton,
		BaseInput,
		BaseSelect
	},
	setup () {
		const isAdmin = JSON.parse(localStorage.account).isAdmin
		const { errorAlert } = useAlert()
		const state = reactive({
			entity: '',
			tab: 'earners',
			tabs: {},
			modal: false,
			fishers: {},
			idFisher: '',
			selectedMethodId: null,
			dateJournal: null
		})

		const isFisher = computed(() => state.tab === 'earners')
		const isJournals = computed(() => state.tab === 'journals')

		Object.entries(BOOKS).forEach(([key, { slim, path }]) => {
			if (slim) {
				state.tabs[key] = slim
			}
		})

		const selectItem = async ({ id }) => {
			state.modal = true
			state.entity = id

			if (isFisher.value) {
				const { get, data } = useApi(`/boardCompany/${state.entity}`)

				await get()

				const workers = data.value.listOfWorkers

				workers.forEach(({ idWorker: id, postName, workerFIO }, index) => {
					if (workers.length === 1 && !index) {
						selectFisher(id)
					}
					state.fishers[id] = { postName, workerFIO }
				})
			}
		}

		const selectFisher = (id) => {
			state.idFisher = state.idFisher ? '' : id
		}

		const { inputs } = useInputsFromTo()

		const onClose = () => {
			state.entity = ''
			state.idFisher = ''
			state.fishers = {}
		}

		const downloadReport = (data, path) => {
			const blob = new Blob([data], { type: 'application/pdf' })

			const URL = window.URL || window.webkitURL
			const url = window.URL.createObjectURL(blob)
			const link = document.createElement('a')
			link.target = '_blank'
			link.href = url
			link.setAttribute('download', `Отчёт_${path}.pdf`)

			document.body.appendChild(link)
			link.click()
			link.parentNode.removeChild(link)
			URL.revokeObjectURL(url)

			state.modal = false
		}

		const saveReport = async () => {
			const api = axios.create({
				baseURL: window.location.origin
			})

			const path = `/getReport/${BOOKS[state.tab].report}`
			const params = {
				startDate: inputs.from.value,
				endDate: inputs.to.value
			}
			if (isFisher.value) {
				params.idWorker = state.idFisher
				params.id = state.entity
			} else if (isJournals.value) {
				params.licenseId = state.entity
				params.fishingMethodId = state.selectedMethodId
				params.dateStart = params.startDate
				params.dateEnd = params.endDate
				delete params.startDate
				delete params.endDate
			} else {
				params.id = state.entity
			}

			try {
				const { data } = await api.get(`/api${path}`, {
					headers: {
						Authorization: window.localStorage.getItem('access_token')
					},
					responseType: 'arraybuffer',
					params
				})

				downloadReport(data, path)
			} catch (e) {
				errorAlert('Ошибка формирования отчёта, попробуйте другую дату или добавьте способ добычи')
			}
		}

		return {
			isAdmin,
			BOOKS,
			state,
			...toRefs(state),
			isFisher,
			isJournals,
			selectItem,

			selectFisher,

			inputs,
			saveReport,

			onClose
		}
	}
}
</script>
